import styled from 'styled-components';

export const MainDiv = styled.div`
    display: flex;
    justify-content: center;

    @media(max-width:800px){
        flex-direction: column;
        }
`

export const LeftDiv = styled.div`

`

export const RightDiv = styled.div`

`

export const BottomDiv = styled.div`

`

